import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppDatabase } from '../app.database';
import { KeyValueEntity } from '../entities/key-value-entity';
import { BaseRepository } from './base-repository';
import { ExamSectionBreakResponseDto } from '../../model/exam/exam-section-break-response-dto';
import { KeyValue } from '@angular/common';
import { LockedState } from '../../service/lockout.service';

@Injectable({
	providedIn: 'root'
})
export class KeyValueRepositoryService extends BaseRepository<KeyValueEntity, string, object> {


	constructor(protected override db: AppDatabase) {
		super(db, 'keyValues');
	}

	public getCurrentBlock(): Observable<number> {
		return this.get('key', 'currentBlock')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return keyValueEntity?.value;
			}));
	}

	public setCurrentBlock(currentBlock: number): Observable<void> {
		return this.get('key', 'currentBlock')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = currentBlock;
				} else {
					existingValue = {
						key: 'currentBlock',
						value: currentBlock
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}



	public setLearnerName(learnerName: string): Observable<void> {
		return this.get('key', 'learnerName')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = learnerName;
				} else {
					existingValue = {
						key: 'learnerName',
						value: learnerName
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getLearnerName(): Observable<string> {
		return this.get('key', 'learnerName')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return keyValueEntity?.value;
			}));
	}

	public setSubjectName(subjectName: string): Observable<void> {
		return this.get('key', 'subjectName')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = subjectName;
				} else {
					existingValue = {
						key: 'subjectName',
						value: subjectName
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getSubjectName(): Observable<string> {
		return this.get('key', 'subjectName')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return keyValueEntity?.value;
			}));
	}

	public setScheduledFinish(scheduledFinish: Date): Observable<void> {
		return this.get('key', 'scheduledFinish')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = scheduledFinish;
				} else {
					existingValue = {
						key: 'scheduledFinish',
						value: scheduledFinish
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getScheduledFinish(): Observable<Date> {
		return this.get('key', 'scheduledFinish')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return new Date(keyValueEntity?.value);
			}));
	}

	public setCurrentQuestionNumber(currentQuestionNumber: number): Observable<void> {
		return this.get('key', 'currentQuestionNumber')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = currentQuestionNumber;
				} else {
					existingValue = {
						key: 'currentQuestionNumber',
						value: currentQuestionNumber
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getCurrentQuestionNumber(): Observable<number> {
		return this.get('key', 'currentQuestionNumber')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return Number(keyValueEntity?.value);
			}));
	}

	public getCurrentSectionNumber(): Observable<number> {
		return this.get('key', 'currentSectionNumber')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return keyValueEntity?.value;
			}));
	}

	public setCurrentSectionNumber(currentSectionNumber: number): Observable<void> {
		return this.get('key', 'currentSectionNumber')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = currentSectionNumber;
				} else {
					existingValue = {
						key: 'currentSectionNumber',
						value: currentSectionNumber
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public setPauseTime(pauseTime: Date): Observable<void> {
		return this.get('key', 'pauseTime')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = pauseTime;
				} else {
					existingValue = {
						key: 'pauseTime',
						value: pauseTime
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getPauseTime(): Observable<Date> {
		return this.get('key', 'pauseTime')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return new Date(keyValueEntity?.value);
			}));
	}

	public setInBreak(value: boolean): Observable<void> {
		return this.get('key', 'break')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = value;
				} else {
					existingValue = {
						key: 'break',
						value: value
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getBreakTimes(): Observable<ExamSectionBreakResponseDto> {
		return this.get('key', 'breakTimes')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return keyValueEntity?.value;
			}));
	}

	public getInBreak(): Observable<boolean> {
		return this.get('key', 'break')
			.pipe(map((keyValueEntity: KeyValueEntity) => {

				if (!keyValueEntity) {
					return false;
				}

				return keyValueEntity?.value;
			}));
	}

	public setBreakTimes(value: ExamSectionBreakResponseDto): Observable<void> {
		return this.get('key', 'breakTimes')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = value;
				} else {
					existingValue = {
						key: 'breakTimes',
						value: value
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getExamType(): Observable<string> {
		return this.get('key', 'examType')
			.pipe(map((KeyValueEntity: KeyValueEntity) => {
				return KeyValueEntity?.value;
			}));
	}

	public setExamType(examType: string): Observable<void> {
		return this.get('key', 'examType')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = examType;
				} else {
					existingValue = {
						key: 'examType',
						value: examType
					};
				}
				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public setChecksum(checksum: Uint8Array): Observable<void> {
        return this.get('key', 'checksum')
                    .pipe(map((existingValue: KeyValueEntity) => {
                        if (existingValue) {
                            existingValue.value = checksum;
                        } else {
                            existingValue = {
                                key: 'checksum',
                                value: checksum
                            };
                        }

                        return existingValue;
                    })).pipe(switchMap((existingValue: KeyValueEntity) => {
                        return this.put(existingValue);
                    }));
    }

    public getChecksum(): Observable<Uint8Array> {
        return this.get('key', 'checksum')
                    .pipe(map((keyValueEntity: KeyValueEntity) => {
                        return keyValueEntity?.value as Uint8Array;
                    }));
	}

	public convertDto(entity: KeyValueEntity): object {
		throw new Error("Method not implemented.");
	}
	public convertEntity(dto: object): KeyValueEntity {
		throw new Error("Method not implemented.");
	}

	public setEndTime(endTime: Date): Observable<void> {
		return this.get('key', 'endTime')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = endTime;
				} else {
					existingValue = {
						key: 'endTime',
						value: endTime
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getEndTime(): Observable<Date> {
		return this.get('key', 'endTime')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return new Date(keyValueEntity?.value);
			}));
	}

	public getLockedState(): Observable<LockedState> {
		return this.get('key', 'locked')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return keyValueEntity?.value;
			}));
	}

	public setLockedState(value: LockedState): Observable<void> {
		return this.get('key', 'locked')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = value;
				} else {
					existingValue = {
						key: 'locked',
						value: value
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public setExamCodeChecksum(examCode: string): Observable<void> {
		return this.get('key', 'examCode')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = examCode;
				} else {
					existingValue = {
						key: 'examCode',
						value: examCode
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getExamCodeChecksum(): Observable<string> {
		return this.get('key', 'examCode')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return keyValueEntity?.value;
			}));
	}

	public setMockFlag(isMock: boolean): Observable<void> {
		return this.get('key', 'isMock')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = isMock;
				} else {
					existingValue = {
						key: 'isMock',
						value: isMock
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getMockFlag(): Observable<boolean> {
		return this.get('key', 'isMock')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return keyValueEntity?.value;
			}));
	}

	public setIntroductionGuidance(introductionGuidance: string): Observable<void> {
		return this.get('key', 'introductionGuidance')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = introductionGuidance;
				} else {
					existingValue = {
						key: 'introductionGuidance',
						value: introductionGuidance
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getIntroductionGuidance(): Observable<string> {
		return this.get('key', 'introductionGuidance')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				return keyValueEntity?.value;
			}));
	}

	public setIsPaused(value: boolean): Observable<void> {
		return this.get('key', 'paused')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = value;
				} else {
					existingValue = {
						key: 'paused',
						value: value
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getIsPaused(): Observable<boolean> {
		return this.get('key', 'paused')
			.pipe(map((keyValueEntity: KeyValueEntity) => {

				if (!keyValueEntity) {
					return false;
				}

				return keyValueEntity?.value;
			}));
	}

	public getPaperCode(): Observable<string> {
		return this.get('key', 'paperCode')
			.pipe(map((keyValueEntity: KeyValueEntity) => {
				if (!keyValueEntity) {
					return "";
				}

				return keyValueEntity?.value;
			}));
	}

	public setPaperCode(value: string): Observable<void> {
		return this.get('key', 'paperCode')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = value;
				} else {
					existingValue = {
						key: 'paperCode',
						value: value
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}
	public getCanNavigateAway(): Observable<boolean> {
		return this.get('key', 'canNavigateAway')
			.pipe(map((keyValueEntity: KeyValueEntity) => {

				if (!keyValueEntity) {
					return false;
				}

				return keyValueEntity?.value;
			}));
	}
	public setCanNavigateAway(canNavigate: boolean): Observable<void> {
		return this.get('key', 'canNavigateAway')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = canNavigate;
				} else {
					existingValue = {
						key: 'canNavigateAway',
						value: canNavigate
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getMockUploaded(): Observable<boolean> {
		return this.get('key', 'mockUploaded')
			.pipe(map((keyValueEntity: KeyValueEntity) => {

				if (!keyValueEntity) {
					return false;
				}

				return keyValueEntity?.value;
			}));
	}
	public setMockUploaded(mockUploaded: boolean): Observable<void> {
		return this.get('key', 'mockUploaded')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = mockUploaded;
				} else {
					existingValue = {
						key: 'mockUploaded',
						value: mockUploaded
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}

	public getMockReference(): Observable<string> {
		return this.get('key', 'mockReference')
			.pipe(map((keyValueEntity: KeyValueEntity) => {

				if (!keyValueEntity) {
					return false;
				}

				return keyValueEntity?.value;
			}));
	}
	public setMockReference(mockReference: string): Observable<void> {
		return this.get('key', 'mockReference')
			.pipe(map((existingValue: KeyValueEntity) => {
				if (existingValue) {
					existingValue.value = mockReference;
				} else {
					existingValue = {
						key: 'mockReference',
						value: mockReference
					};
				}

				return existingValue;
			})).pipe(switchMap((existingValue: KeyValueEntity) => {
				return this.put(existingValue);
			}));
	}



}
