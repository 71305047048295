@if (labelText) {
  <div class="outer-wrapper h-100">
    <div class="wrapper" spellcheck="false" [ngStyle]="getFlexDirection()">
      <label class="or-question-text" [ngStyle]="getLabelStyles()"><b [innerHTML]="labelText"></b></label>
      <textarea class="or customPadding" spellcheck="false" [ngStyle]="getTextAreaStyles()" [ngModel]="answerText" (ngModelChange)="onAnswerTextChange($event)"></textarea>
    </div>
    @if (includeWordCount) {
      <div class="word-count">{{getAnswerLength(answerText)}} words</div>
    }
  </div>
}
@if (!labelText) {
  <div class="h-100 outer-wrapper">
    <div class="wrapper">
      <textarea class="or" spellcheck="false" [ngModel]="answerText" [ngStyle]="{ 'height': editorHeight }" (ngModelChange)="onAnswerTextChange($event)"></textarea>
    </div>
    @if (includeWordCount) {
      <div class="word-count">{{getAnswerLength(answerText)}} words</div>
    }
  </div>
}
