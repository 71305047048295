import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { KeyValueDataService } from '../../../../database/services/key-value-data.service';
import { QuestionStateDto } from '../../../../model/exam/state/question-state-dto';
import { DialogService } from '../../../../service/dialog.service';
import { ErrorService } from '../../../../service/error/error.service';
import { ExamToolsService } from '../../../../service/exam-tool.service';
import { MockExamNavigationService } from '../../../../service/mock-exam-navigation.service';
import { ProtractorService } from '../../../../service/protractor.service';
import { MathsTool } from '../../../exam/toolbars/maths-toolbar/classes/maths-tool';
import { ExamToolIdentifier } from '../../../exam/toolbars/maths-toolbar/classes/tool.enum';
import { ExamRoutes } from '../../../../enum/exam-routes';
import { Router } from '@angular/router';

@Component({
	selector: 'app-mock-footer',
	templateUrl: './exam-footer.component.html',
	styleUrls: ['./exam-footer.component.scss']
})
export class MockExamFooterComponent implements OnInit {

	@Input() public text!: string;
	@Input() public enableFlagBtn: boolean = true;
	@Input() public enableErrorBtn: boolean = true;
	@Input() public nextButtonLabel: string = "Next";
	@Input() public previousButtonLabel: string = "Previous";
	@Input() public isExam: boolean = false;
	@Input() public enablePreviousButton: boolean = true;
	@Input() public enableNextButton: boolean = true;
	@Input() public isMathsExam: boolean = false;

	@Output() public previousButtonAction = new EventEmitter<void>();
	@Output() public nextButtonAction = new EventEmitter<void>();
	@Output() public endExamAction = new EventEmitter<void>();


	public ExamRoutes = ExamRoutes;
	public isLastQuestion: boolean = false;
	public currentQuestionIndex!: number;
	public examType!: string;
	public ExamToolIdentifier = ExamToolIdentifier;
	public showProtractorSlider: boolean = false;
	public sizeRatio: number = 1;

	public selectedActions?: string[] = [];
	public flaggedQuestions: number[] = [];

	constructor(private examNavigationService: MockExamNavigationService,
		private errorService: ErrorService,
		private dialogService: DialogService,
		private keyValueDataService: KeyValueDataService,
		private toolService: ExamToolsService,
		private protractorService: ProtractorService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private router: Router
	) {
		this.matIconRegistry.addSvgIcon(
			'Protractor',
			this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svgs/Protractor.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'Ruler',
			this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svgs/Ruler.svg')
		);
	}

	public ngOnInit(): void {
		if (this.isExam) {
			forkJoin({
				lastQuestionIndex: this.examNavigationService.getLastQuestionIndex(),
				currentQuestion: this.examNavigationService.getCurrentQuestion(),
				flaggedQuestions: this.examNavigationService.getFlaggedQuestionNumbers()
			}).subscribe((data: { lastQuestionIndex: number, currentQuestion: QuestionStateDto, flaggedQuestions: number[] }) => {
				this.flaggedQuestions = data.flaggedQuestions;
				this.handleChanges(data.currentQuestion, data.lastQuestionIndex);
			});

			this.examNavigationService.currentQuestionChange$.subscribe((currentQuestion: QuestionStateDto) => {
				this.examNavigationService.getLastQuestionIndex()
					.pipe(first())
					.subscribe((lastQuestionIndex: number) => {
						this.handleChanges(currentQuestion, lastQuestionIndex);
					});
			});

			this.keyValueDataService.getExamType().subscribe((examType: string) => {
				this.examType = examType;
			});

			this.examNavigationService.flaggedQuestionsChange$.subscribe((flaggedNumbers: number[]) => {
				this.flaggedQuestions = flaggedNumbers;
			});

			this.toolService.currentToolChange$.subscribe((val: MathsTool) => {
				if (val) {
					this.showHideProtractorSlider(val.type, val.enabled);
				}
			});
		}
	}

	public handleChanges(question: QuestionStateDto, lastQuestionIndex: number): void {
		this.isLastQuestion = question.question.orderIndex === lastQuestionIndex;
		this.currentQuestionIndex = question.question.orderIndex;
	}

	public questionIsFlagged(): boolean {
		return this.flaggedQuestions.indexOf(this.currentQuestionIndex) !== -1;
	}

	public toggleTool(type: ExamToolIdentifier): void {
		return this.toolService.showHideTool(type);
	}

	public flagCurrentQuestion(): void {
		this.examNavigationService.flagCurrentQuestion();
	}

	public showThemesModal(): void {
		this.dialogService.showThemeSelectionModal();
	}

	public invokeNext(): void {
		this.nextButtonAction.emit();
	}

	public invokePrevious(): void {
		this.previousButtonAction.emit();
	}

	public leaveExam(): void {
		if (confirm('Would you like to finish this mock exam?')) {
			this.endExamAction.emit();
		}
	}


	public performBackNavigation(route: ExamRoutes): void {
		switch (route) {
			case ExamRoutes.Home:
				this.router.navigateByUrl("/");
				break;
			case ExamRoutes.MocksHome:
				this.router.navigateByUrl('/mock/units');
				break;
			default:
				break;
		}
	}

	public updateProtractorSizeRatio(newSizeRatio: number) {
		this.protractorService.updateProtractorSizeRatio(newSizeRatio);
	}

	private showHideProtractorSlider(type: ExamToolIdentifier, value: boolean): void {

		switch (type) {
			case ExamToolIdentifier.Protractor:
				this.showProtractorSlider = value;
				break;
			default:
		}
	}
}
