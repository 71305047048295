import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { ILayoutGridItem } from '../../model/exam/i-layout-grid-item';
import { CandidateQuestionDto } from '../../model/exam/candidate-question-dto';
import { QuestionStateDto } from '../../model/exam/state/question-state-dto';
import { AssetEntity } from '../entities/asset-entity';
import { CandidateQuestionEntity } from '../entities/candidate-question-entity';
import { QuestionAnswerEntity } from '../entities/question-answer-entity';
import { QuestionSectionEntity } from '../entities/question-section-entity';
import { AssetRepositoryService } from '../repositories/asset-repository.service';
import { CandidateQuestionRepositoryService } from '../repositories/candidate-question-repository.service';
import { KeyValueRepositoryService } from '../repositories/key-value-repository.service';
import { QuestionAnswerRepositoryService } from '../repositories/question-answer-repository.service';
import { QuestionSectionRepositoryService } from '../repositories/question-section-repository.service';
import { ArrayHelperService } from '../../common/services/array.service';

@Injectable({
	providedIn: 'root'
})
export class CandidateQuestionDataService {

	constructor(private candidateQuestionRepository: CandidateQuestionRepositoryService,
		private keyValueRepository: KeyValueRepositoryService,
		private questionAnswerRepository: QuestionAnswerRepositoryService,
		private assetRepository: AssetRepositoryService,
		private questionSectionRepositoryService: QuestionSectionRepositoryService) {
		this.getCurrentQuestionState = this.getCurrentQuestionState.bind(this);
	}

	public getCurrentQuestionState(): Observable<QuestionStateDto> {
		return this.keyValueRepository.getCurrentQuestionNumber()
			.pipe(mergeMap((questionNumber: number) => this.candidateQuestionRepository.get('orderIndex', questionNumber)))
			.pipe(switchMap((candidateQuestionEntity: CandidateQuestionEntity) => this.getQuestionState(candidateQuestionEntity)));
	}

	public getLastQuestionState(): Observable<QuestionStateDto> {
		return this.candidateQuestionRepository.getLastQuestion()
			.pipe(mergeMap((entity: CandidateQuestionEntity) => this.getQuestionState(entity)));
	}

	public getQuestionsById(candidateQuestionIds: string[]): Observable<CandidateQuestionDto[]> {
		return this.candidateQuestionRepository.getWhere('candidateQuestionId', candidateQuestionIds)
			.pipe(map((val) => val.map(this.candidateQuestionRepository.convertDto)));
	}

	public getAllQuestionStates(): Observable<QuestionStateDto[]> {

		return forkJoin({
			questions: this.candidateQuestionRepository.getAll().pipe(map((cqs) => cqs.map(x => this.candidateQuestionRepository.convertDto(x)))),
			questionAnswers: this.questionAnswerRepository.getAll().pipe(map((qas) => qas.map(x => this.questionAnswerRepository.convertDto(x))))
		})
		.pipe(map((val) => {
			return val.questions.map((question: CandidateQuestionDto) => {
				return {
					question: question,
					layoutItems: val.questionAnswers.filter(qa => question.id === qa.candidateQuestionId)
				}
			});
		}));
	}

	private getQuestionState(candidateQuestionEntity: CandidateQuestionEntity): Observable<QuestionStateDto> {
		const candidateQuestionDto = this.candidateQuestionRepository.convertDto(candidateQuestionEntity);
		const questionAnswerObservable = this.questionAnswerRepository.getMultiple('candidateQuestionId', candidateQuestionEntity.candidateQuestionId)
			.pipe(map((entities: QuestionAnswerEntity[]) => entities.map(this.questionAnswerRepository.convertDto)));
		const questionAssetObservable = this.assetRepository.getMultiple('candidateQuestionId', candidateQuestionEntity.candidateQuestionId)
			.pipe(map((entities: AssetEntity[]) => entities.map(this.assetRepository.convertDto)));

		return forkJoin({
			questionAnswers: questionAnswerObservable,
			questionAssets: questionAssetObservable
		}).pipe(map((data: { questionAnswers: ILayoutGridItem[], questionAssets: ILayoutGridItem[] }) => {
			const layoutItems: ILayoutGridItem[] = data.questionAnswers.concat(data.questionAssets).sort((o1, o2) => o1.layout.orderIndex - o2.layout.orderIndex);

			return {
				question: candidateQuestionDto,
				layoutItems
			};
		}));
	}

	public getQuestion(questionNumber: number): Observable<QuestionStateDto> {
		return this.getQuestionStateByOrderIndex(questionNumber);
	}

	public getNextQuestion(): Observable<QuestionStateDto> {
		return this.keyValueRepository.getCurrentQuestionNumber()
			.pipe(map((currentQuestionNumber: number) => currentQuestionNumber + 1))
			.pipe(mergeMap((updatedQuestionNumber: number) => this.getQuestionStateByOrderIndex(updatedQuestionNumber)));
	}

	public getPreviousQuestion(): Observable<QuestionStateDto> {
		return this.keyValueRepository.getCurrentQuestionNumber()
			.pipe(map((currentQuestionNumber: number) => currentQuestionNumber - 1))
			.pipe(mergeMap((updatedQuestionNumber: number) => this.getQuestionStateByOrderIndex(updatedQuestionNumber)));
	}

	public getQuestionCount(): Observable<number> {
		return this.candidateQuestionRepository.count();
	}

	public getFlaggedQuestionNumbers(): Observable<number[]> {
		return this.candidateQuestionRepository.getAll()
			.pipe(map((entities: CandidateQuestionEntity[]) => {
				return entities.filter(x => x.flagged).map(x => x.orderIndex);
			}));
	}

	public flagCurrentQuestion(): Observable<void> {
		return this.keyValueRepository.getCurrentQuestionNumber()
			.pipe(mergeMap((questionNumber: number) => this.candidateQuestionRepository.get('orderIndex', questionNumber)))
			.pipe(switchMap((candidateQuestionEntity: CandidateQuestionEntity) => {
				candidateQuestionEntity.flagged = !candidateQuestionEntity.flagged;
				return this.candidateQuestionRepository.put(candidateQuestionEntity);
			}));
	}

	public getAllQuestions(): Observable<CandidateQuestionDto[]> {
		return this.candidateQuestionRepository.getAll()
			.pipe(map((questions: CandidateQuestionEntity[]) => questions.map(this.candidateQuestionRepository.convertDto)));
	}

	public checkFlaggedQuestions(): Observable<boolean> {
		return this.candidateQuestionRepository.checkFlaggedQuestions();
	}

	public checkNonAttemptedResponses(): Observable<boolean> {
		return this.candidateQuestionRepository.checkNonAttemptedResponses();
	}

	public getCurrentSectionFlaggedQuestions(): Observable<CandidateQuestionDto[]> {
		return this.keyValueRepository.getCurrentSectionNumber()
			.pipe(mergeMap((sectionNumber: number) => this.questionSectionRepositoryService.getBySectionNumber(sectionNumber)))
			.pipe(mergeMap((questionSection: QuestionSectionEntity) => this.candidateQuestionRepository.getSectionQuestions(questionSection.questionSectionId)))
			.pipe(map((candidateQuestions: CandidateQuestionEntity[]) => {
				const flaggedQuestions: CandidateQuestionDto[] = candidateQuestions.filter(x => x.flagged).map(x => this.candidateQuestionRepository.convertDto(x));
				return flaggedQuestions.sort((a, b) => b.orderIndex - a.orderIndex);
			}));
	}

	public getCurrentSectionNonAttemptedQuestions(): Observable<CandidateQuestionDto[]> {
		return this.keyValueRepository.getCurrentSectionNumber()
			.pipe(mergeMap((sectionNumber: number) => this.questionSectionRepositoryService.getBySectionNumber(sectionNumber)))
			.pipe(mergeMap((questionSection: QuestionSectionEntity) => this.candidateQuestionRepository.getSectionQuestions(questionSection.questionSectionId)))
			.pipe(mergeMap((candidateQuestions: CandidateQuestionEntity[]) => forkJoin({
				candidateQuestions: of(candidateQuestions),
				questionAnswers: this.questionAnswerRepository.getWhere('candidateQuestionId', candidateQuestions.map(x => x.candidateQuestionId))
			})))
			.pipe(map((data: { questionAnswers: QuestionAnswerEntity[], candidateQuestions: CandidateQuestionEntity[] }) => {
				const unansweredQuestions: CandidateQuestionDto[] = [];
				data.candidateQuestions.map((candidateQuestion: CandidateQuestionEntity) => {
					const questionAnswers = data.questionAnswers.filter(x => x.candidateQuestionId === candidateQuestion.candidateQuestionId).map(x => this.questionAnswerRepository.convertDto(x));

					if (questionAnswers.length && !questionAnswers.some(questionAnswer => questionAnswer.answer.answered())) {
						unansweredQuestions.push(this.candidateQuestionRepository.convertDto(candidateQuestion));
					}
				});

				return unansweredQuestions.sort((a, b) => b.orderIndex - a.orderIndex);
			}));
	}


	public getFirstQuestionFromSectionQuestion(sectionNumber: number): Observable<CandidateQuestionDto> {
		return this.questionSectionRepositoryService.getBySectionNumber(sectionNumber)
			.pipe(mergeMap((questionSection: QuestionSectionEntity) => this.candidateQuestionRepository.getSectionQuestions(questionSection.questionSectionId)))
			.pipe(map((candidateQuestions: CandidateQuestionEntity[]) => this.candidateQuestionRepository.convertDto(candidateQuestions.sort((a, b) => ArrayHelperService.sortByProperty(a, b, "orderIndex"))[0])));
	}

	public getQuestionByOrderIndex(orderIndex: number): Observable<CandidateQuestionDto> {
		return this.candidateQuestionRepository.get('orderIndex', orderIndex)
			.pipe(map((candidateQuestion: CandidateQuestionEntity) => this.candidateQuestionRepository.convertDto(candidateQuestion)));
	}

	public getQuestionStateByOrderIndex(orderIndex: number): Observable<QuestionStateDto> {
		return this.candidateQuestionRepository.get('orderIndex', orderIndex)
			.pipe(mergeMap((candidateQuestion: CandidateQuestionEntity) => this.getQuestionState(candidateQuestion)));
	}
}
