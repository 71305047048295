<div class="footer container-fluid row bs fs">
    <div class="col-4 offset-4 d-flex justify-content-between vertical-align"
         [ngClass]="{ 'justify-content-between': enablePreviousButton && enableFlagBtn && enableNextButton,'justify-content-center': !enableFlagBtn}">
        @if (enablePreviousButton) {
        <button mat-raised-button
                class="tbp"
                (click)="invokePrevious()"
                [disabled]="isFirstQuestionInSection() || isSaving">
            <mat-icon>arrow_back</mat-icon>&nbsp;{{previousButtonLabel}}
        </button>
        }
        @if (isExam) {
        <mat-button-toggle-group [(ngModel)]="selectedActions" [multiple]="true">
            @if (isMathsExam) {
            <mat-button-toggle class="tbp" (click)="toggleTool(ExamToolIdentifier.Protractor)" value="ruler"><mat-icon svgIcon="Protractor" matTooltip="Protractor" matTooltipPosition="right"></mat-icon></mat-button-toggle>
            }
            @if (isMathsExam) {
            <mat-button-toggle class="tbp" (click)="toggleTool(ExamToolIdentifier.Ruler)" value="protractor"><mat-icon svgIcon="Ruler" matTooltip="Ruler" matTooltipPosition="right"></mat-icon></mat-button-toggle>
            }
            @if (enableFlagBtn) {
            <mat-button-toggle class="tbp" (click)="flagCurrentQuestion()" [checked]="questionIsFlagged()"><mat-icon>flag</mat-icon>&nbsp;Flag</mat-button-toggle>
            }
        </mat-button-toggle-group>
        }
        @if (isTutorial) {
        <mat-button-toggle-group [(ngModel)]="selectedActions" [multiple]="true">
            @if (showMathTools()) {
            <mat-button-toggle class="tbp" (click)="toggleTool(ExamToolIdentifier.Protractor)" value="ruler"><mat-icon svgIcon="Protractor" matTooltip="Protractor" matTooltipPosition="right"></mat-icon></mat-button-toggle>
            }
            @if (showMathTools()) {
            <mat-button-toggle class="tbp" (click)="toggleTool(ExamToolIdentifier.Ruler)" value="protractor"><mat-icon svgIcon="Ruler" matTooltip="Ruler" matTooltipPosition="right"></mat-icon></mat-button-toggle>
            }
        </mat-button-toggle-group>
        }
        @if (enableNextButton && !isFinalQuestion()) {
        <button mat-raised-button
                class="tbp"
                (click)="invokeNext()"
                [disabled]="isSaving">
            {{nextButtonLabel}}&nbsp;
            <mat-icon>arrow_forward</mat-icon>
        </button>
        }
        @if (enableNextButton && isFinalQuestion()) {
        <button mat-raised-button
                class="tbp"
                (click)="invokeEnd()"
                [disabled]="isSaving">
            {{nextButtonLabel}}&nbsp;
            <mat-icon>arrow_forward</mat-icon>
        </button>
        }
    </div>
    @if (showProtractorSlider) {
    <div class="protractor-slider">
        <span>Resize Protractor</span>
        <mat-slider discrete min="0.5" max="1.5" step="0.01">
            <input matSliderThumb (ngModelChange)="updateProtractorSizeRatio($event)" [(ngModel)]="sizeRatio" />
        </mat-slider>
    </div>
    }
</div>
